<template>
  <base-section
    id="why-impot-ad"
    class="grey lighten-3"
    space="30"
  >
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-vuetify"
      :title="data.why_us_title"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="block in blocks"
          :key="block._key"
          cols="12"
          md="4"
        >
          <base-info-card :title="block.title" :text="block.description" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import sanity from "@/client";

const query = `*[_type == "homepage"]{
  _id,
  why_us_title,
  why_us_blocks
}[0]`;

  export default {
    name: 'WhyImpotAd',
    created() {
      this.fetchData();
    },
    computed: {
      blocks() {
        return this.data.why_us_blocks;
      }
    },
    data: () => {
      return {
        data: [],
      }
    },
    methods: {
      fetchData() {
        sanity.fetch(query).then(
          (data) => {
            this.data = data;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }
</script>

<style scoped>

</style>
